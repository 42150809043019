import PSFPublicContext = psfapi.PSFPublicContext;
import TranslatorFactory = psfapi.TranslatorFactory;
import Translator = psfapi.Translator;

jQuery(document).ready(function () {
    PSFPublicContext.init().then((psfPublicContext: PSFPublicContext) => {
        const translatorFactory: TranslatorFactory = psfPublicContext.getTranslatorFactory();
        translatorFactory.getTranslatorWithBackend('../public/localisations/localisation_{{lng}}.json').then((translator: Translator) => {
            translator.translateTree($(document.documentElement));
        });
    });
});
